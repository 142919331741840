import styled, { css } from 'styled-components';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const sectionStyles = theme => css`
  background-color: ${theme === 'dark' ? colors.gray[900] : colors.base.white};
`;
export const wrapperStyles = css`
    display: flex;
    flex-direction: column;
    gap: 40px;
    ${atMinWidth.sm`
        gap: 48px;
    `}
    ${atMinWidth.md`
        max-width: 840px;
        gap: 56px;
    `}
    ${atMinWidth.xl`
      max-width: 1240px;
      display: grid;
      grid-template-columns: 400px 1fr;
      gap: 100px;
    `}
`;
export const Text = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  ${atMinWidth.xl`
      position: sticky;
      top: 96px;
      text-align: left;
  `}
  .heading {
      ${font('display', 'md', '700')}
      color: ${props =>
        props.theme === 'dark' ? colors.base.white : colors.gray[900]};
      ${atMinWidth.sm`
          ${font('display', 'lg', '700')}
      `}
      ${atMinWidth.md`
          ${font('display', 'xl', '700')}
      `}
  }
  .subhead {
      ${font('text', 'lg', '400')}
      color: ${props =>
        props.theme === 'dark' ? colors.base.white : colors.gray[600]};
      ${atMinWidth.md`
          ${font('text', 'xl', '400')}
      `}
  }
`;
export const List = styled.ul`
  width: 100%;
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const ListItem = styled.li`
  width: 100%;
  && .list-link {
    width: 100%;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px;
    border-bottom: 1px solid ${colors.gray[300]};
    color: ${props =>
      props.theme === 'dark' ? colors.green[300] : colors.primary[500]};
    div {
      width: 100%;
    }
    svg {
      width: 24px;
      height: 24px;
    }
    &:hover {
      color: ${props =>
        props.theme === 'dark' ? colors.green[100] : colors.primary[300]};
    }
  }
`;
